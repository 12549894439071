<template>
  <div
    class="d-flex flex-column flex-wrap align-center py-4 justify-center"
    style="height: 100%;"
  >
    <img :src="image" :style="{ width: imageWidth }" />
    <h4 class="ft font-weight-medium mt-2 ">
      {{ title }}
    </h4>
    <p class="ft text-sm font-weight-medium px-1 pt-1  text-center">
      {{ subTitle }}
    </p>
    <p>
      <slot name="action"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "EmptyPage",
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: ""
    },
    imageWidth: {
      type: String,
      default: "70px"
    }
  }
};
</script>

<style scoped></style>
